import { StyledGiantBox } from "../../commons/Content.styles";
import {
  StyledTitleName,
  StyledSubtitle,
  StyledHomeDiv,
  StyledHomeLinks,
  StyledHomeMenuButton,
  StyledExternalLink,
  StyledMailButton,
  StyledToolTip,
  StyledExternalLinksDiv
} from "./HomePage.styles";
import { ColorBlock } from "../../commons/Decorations.styles";
import { LinkedIn, GitHub, EmailSharp } from '@mui/icons-material';
import { Fade } from "@mui/material";
import { useState } from "react";

const HomePage = ({ isMenuClicked, setIsMenuClicked, setIsHamburgerOpen }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const menuButtonsArr = [
    { name: 'See Projects', link: '/projects', bgColor: 'linear-gradient(to right bottom, #FFA3DA, #FF8CD1)', textColor: '#000000' },
    { name: 'Learn More', link: '/about', bgColor: 'linear-gradient(to right bottom, #FFDD86, #FFD568)', textColor: '#000000' },
  ];

  const outLinksArr = [
    { name: 'LinkedIn', link: 'https://www.linkedin.com/in/alyssa-villanueva-9542a72bb/', icon: <LinkedIn style={{fill: "#FFFFFF", fontSize: 40}} />, description: "Go to Alyssa's LinkedIn profile"},
    { name: 'GitHub', link: 'https://github.com/alyssav0831', icon: <GitHub style={{fill: "#FFFFFF", fontSize: 35, verticalAlign: "middle"}} />, description: "Go to Alyssa's GitHub profile"},
  ];

  const email = { name: 'Email', email: 'alyssav0831@yahoo.com', icon: <EmailSharp style={{fill: "#FFFFFF", fontSize: 35, verticalAlign: "middle"}} />, description: 'Click to copy "alyssav0831@yahoo.com" to clipboard'};

  const handleEmailClick = () => {
    handleTooltipOpen();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email.email);
      setIsEmailCopied(true);
    }
    else {
      window.clipboardData.setData("Text", email.email);
      setIsEmailCopied(true);
    }

    setTimeout(() => {
      handleTooltipClose();
    }, 3000);

    setTimeout(() => {
      setIsEmailCopied(false);
    }, 3500);
  }

  const handleTooltipClose = () => {
    if (!isEmailCopied) {
      setTooltipOpen(false);
    }
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  
  
  return (
    <StyledGiantBox isMenuClicked={isMenuClicked} justifyContent={'start'}>
      <StyledHomeDiv>
        <StyledTitleName isMenuClicked={isMenuClicked} index={0}>Alyssa Villanueva</StyledTitleName>
        <StyledSubtitle isMenuClicked={isMenuClicked} index={1}>Full-Stack Developer & UX/UI Designer</StyledSubtitle>
        <StyledHomeLinks>
          {
            menuButtonsArr.map(({ name, link, bgColor, textColor }, index) => (
              <StyledHomeMenuButton
                key={`homeMenu-${index}`}
                index={index + 2}
                name={name}
                link={link}
                bgColor={bgColor}
                textColor={textColor}
                setIsMenuClicked={setIsMenuClicked}
                setIsHamburgerOpen={setIsHamburgerOpen}
                isMenuClicked={isMenuClicked}
              />
            ))
          }
        </StyledHomeLinks>
        <StyledExternalLinksDiv>
          {
            outLinksArr.map((prop, index) => (
              <StyledToolTip
                title={prop.description}
                key={`link-${index}`}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "#000000", color: "#FFFFFF", fontSize: 12, textAlign: "center" } }, arrow: { sx: { color: "#000000"}} }}
                isMenuClicked={isMenuClicked}
                index={index + 4}
                arrow
              >
                <StyledExternalLink
                  href={prop.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {prop.icon}
                </StyledExternalLink>
              </StyledToolTip>
            ))
          }
          <StyledToolTip
                title={isEmailCopied ? 'Copied "alyssav0831@yahoo.com" to clipboard!' : email.description}
                sx={{borderRadius: 0}}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                componentsProps={{ tooltip: { sx: { borderRadius: 0, background: "#000000", color: "#FFFFFF", fontSize: 12, textAlign: "center" } }, arrow: { sx: { color: "#000000"}} }}
                open={tooltipOpen}
                isMenuClicked={isMenuClicked}
                index={6}
                arrow
              >
            <StyledMailButton
              onClick={handleEmailClick}
              onMouseEnter={handleTooltipOpen}
              onMouseLeave={handleTooltipClose}
            >
              {email.icon}
            </StyledMailButton>
          </StyledToolTip>
        </StyledExternalLinksDiv>
      </StyledHomeDiv>
      <ColorBlock height={1} color={'#FF8CD1'} fromRight={3} animate={1} />
      <ColorBlock height={2} color={'#7ED8FF'} fromRight={2} animate={3} />
      <ColorBlock height={3} color={'#FFD568'} fromRight={1} animate={2} />
    </StyledGiantBox>
  );
}

export default HomePage;